import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/common/footer"
import NarociloZakljucek from "../components/sections/narocilo-oddano/narocilo-zakljucek"

const NarociloOddano = () => (
  <Layout>
    <SEO title="Naročilo uspešno oddano" />
    <Navigation />
    <NarociloZakljucek />
    <Footer />
  </Layout>
)

export default NarociloOddano