import React, { Component } from "react"
import styled from "styled-components"
import { FaRegLaughBeam } from "react-icons/fa";

import {
	SectionTitle,
	Section,
	StyledContainer,
} from "../../global"

export default class NarociloZakljucek extends Component {
	render() {
		return (
			<Section>
				<StyledContainer>
					<br />
					<br />
					<IconWrapper>
						<Icon />
					</IconWrapper>
					<br />
					<SectionTitle>Naročilo uspešno!</SectionTitle>
					<SectionDescription>
						Vaše naročilo je bilo uspešno oddano, kmalu boste tudi prijeli e-pošto z podatki o naročilu.
						<br />
						<br />
						Zahvaljujemo se za vaš nakup!
					</SectionDescription>
				</StyledContainer>
			</Section>
		)
	}
}

const Icon = styled(FaRegLaughBeam)`
	font-size: 10rem;
	text-align: center;
	color: ${props => props.theme.color.accent};
`
const IconWrapper = styled.div`
	text-align: center;
	margin-top: 10rem;
`

const SectionDescription = styled.div`
	text-align: center;
`